@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(141, 141, 141, 0.4);
    border-radius: 3px;
}

::-webkit-scrollbar-horizontal {
    height: 6px;
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}